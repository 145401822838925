export default {
  'page.video': '影片',
  'module.video': '影片',

  // role
  'ROLE_VIDEO': '影片管理',

  // data
  'video.search_keyword': '輸入標題、簡述、內容搜尋',
  'video.data.type': '影片類型',
  'video.data.type.youtube': 'Youtube',
  'video.data.type.static_video': '靜態影片',
  'video.data.type.file': '檔案資訊',
  'video.data.type.file_id': '系統檔案編號',
  'video.data.youtube_url': 'Youtube影片網址',
  'video.data.type.streaming_vod': 'VOD串流',
  'video.vod_convert_job': '影片轉檔任務',

  // action

  // form
  'video.form_block.video': '影片設定',

  // others
}

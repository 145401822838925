const types = [
  'floor',
  'water',
  'roof',
]

export default {
  TYPE_FLOOR: 'floor', // 地面
  TYPE_WATER: 'water', // 水面
  TYPE_ROOT: 'roof', // 屋頂
  types,
}

export declare type ProjectConstantsType = {
  TYPE_FLOOR: 'floor', // 地面
  TYPE_WATER: 'water', // 水面
  TYPE_ROOT: 'roof', // 屋頂
}

export declare type ProjectType = 'floor'|'water'|'roof'

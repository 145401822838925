// @ts-ignore
import {feature} from '@/config/site.js'

const types = [
  'youtube',
  'static_video',
  'streaming_vod',
]

// site.feature.staticVideoUpload不為ture, 刪除靜態影片類型
if(feature.staticVideoUpload != true) {
  const index = types.indexOf('static_video')
  if(index > -1) types.splice(index, 1)
}

// site.feature.streamingVod不為ture, 刪除串流VOD類型
if(feature.streamingVod != true) {
  const index = types.indexOf('streaming_vod')
  if(index > -1) types.splice(index, 1)
}

export default {
  TYPE_YOUTUBE: 'youtube', // youtube
  TYPE_STATIC_VIDEO: 'static_video', // 靜態影片
  TYPE_STREAMING_VOD: 'streaming_vod', // 串流
  types,
}

export declare type VideoConstantsType = {
  TYPE_YOUTUBE: 'youtube',
  TYPE_STATIC_VIDEO: 'static_video',
  TYPE_STREAMING_VOD: 'streaming_vod',
}

export declare type VideoTypes = 'youtube'|'static_video'|'streaming_vod'

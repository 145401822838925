module.exports = {
  name: '一本企業',
  manifest: {
    themeColor: '#1190cb',
    backgroundColor: '#000000',
  },
  locales: {
    list: ['zh-TW', 'zh-CN', 'en-US'],
    defaultLocale: 'zh-TW',
    defaultTimeZone: 'Asia/Taipei',
  },
  seo: {
    keywords: '太陽能,綠能,自購電廠,再生能源,魚電共生',
    description: `太陽能發電系統設計、規劃,工程承攬,電廠維運管理`,
  },
  sitemap: {
    types: [
      'base',
      'project',
    ],
  },
  feature: {
    /**
     * 各頁面的階層設定(頁面index sidebar使用)
     */
    pageTypes: {
      default: { depth: 1 },
      article: { depth: 2 },
    },

    // 商品分類最多階層數
    productCategoryMaxDepth: 2,

    // SMS功能
    smsModule: false,

    /**
     * iso27001
     */
    iso27001: false,

    /**
     * 靜態影片模組上傳功能
     */
    staticVideoUpload: false,
  },
}

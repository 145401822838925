export default {
  'page.project': '工程實績',
  'page.plan': '計劃書',
  'module.project': '工程實績',
  'module.plan': '計劃書',

  // data
  'data.city': '縣市',
  'data.district': '行政區',
  'data.location': '位置',

  // form
  'project.data.capacity': '總容量',
  'project.data.capacity.help': '單位: kWp, 1MWp請自行換算為1000kWp',
  'project.data.period': '開發時間',
  'project.data.outsourcing_by': '發包單位',
  'project.data.type': '類型',
  'project.data.type.water': '水面型',
  'project.data.type.floor': '地面型',
  'project.data.type.roof': '屋頂型',

  'plan.data.capacity': '總容量',
  'plan.data.capacity.help': '單位: kWp, 1MWp請自行換算為1000kWp',
  'plan.data.daily_performance_of_location': '地區每日發電量均值',
  'plan.data.daily_performance_of_location.help': '度/kWp',
  'plan.data.yearly_income': '平均年收入',
  'plan.data.yearly_income.help': '發電年收入計算公式 = 規劃設計容量 x 台電躉售費率 x 每日發電量均值 x 365天 x 90.5%衰退率',
  'plan.data.photo': '佈局圖片',
  'plan.data.stage': '階段',
  'plan.data.panel_wp': '每片太陽能板功率(Wp)',
  'plan.data.piece': '太陽能板片數',
  'plan.data.rate': '台電躉購電價(度/元)',

  // others
  'project.search_keyword': '輸入工程實績名稱、敘述、內容搜尋',
  'plan.search_keyword': '輸入計劃書名稱、代碼搜尋',
}
